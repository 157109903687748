exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-chessup-js": () => import("./../../../src/pages/projects/chessup.js" /* webpackChunkName: "component---src-pages-projects-chessup-js" */),
  "component---src-pages-projects-cuehit-js": () => import("./../../../src/pages/projects/cuehit.js" /* webpackChunkName: "component---src-pages-projects-cuehit-js" */),
  "component---src-pages-projects-lightricks-design-system-js": () => import("./../../../src/pages/projects/lightricks-design-system.js" /* webpackChunkName: "component---src-pages-projects-lightricks-design-system-js" */),
  "component---src-pages-projects-placeholder-js": () => import("./../../../src/pages/projects/placeholder.js" /* webpackChunkName: "component---src-pages-projects-placeholder-js" */),
  "component---src-pages-projects-snupps-js": () => import("./../../../src/pages/projects/snupps.js" /* webpackChunkName: "component---src-pages-projects-snupps-js" */),
  "component---src-pages-projects-together-js": () => import("./../../../src/pages/projects/together.js" /* webpackChunkName: "component---src-pages-projects-together-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-selected-js": () => import("./../../../src/pages/selected.js" /* webpackChunkName: "component---src-pages-selected-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

